<template>
  <div>
    <TopNavbar />
    <div class="mx-20">
      <div class="alert alert-warning mt-4" v-if="opname.is_active">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="stroke-current shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <span
          >Stok opname is on process. Any stock update will be considered as a
          pending product.</span
        >
      </div>
      <ScanModal @fetchProduct="getProducts" />
      <hr class="mt-4" />
      <div class="mb-4">
        <!-- <router-view/> -->
        <div class="flex justify-between items-center mt-4">
          <p class="text-2xl font-bold">Products</p>
          <div class="space-x-3 flex">
            <SyncronizeStock />
            <ExportExcel />
            <ImportExcel @fetchProduct="getProducts" />
            <div class="join">
              <input
                class="input input-bordered join-item input-sm"
                placeholder="Barcode/Name/SKU/Power Code"
                v-model="searchValue"
                v-debounce:800ms="searchProduct"
              />
              <button class="btn join-item btn-sm" @click="searchProduct">
                Search
              </button>
            </div>
          </div>
        </div>
        <div class="tabs">
          <router-link
            :to="{ name: 'home', query: { tab: item.id } }"
            v-for="item in tabs"
            :key="item.name"
          >
            <a
              class="tab tab-bordered"
              @click="(currentTab = item.id), clickTab()"
              :class="{ 'tab-active': currentTab == item.id }"
              >{{ item.name }}</a
            >
          </router-link>
        </div>
        <div v-if="currentTab == 3">
          <BundleModal @fetchProduct="getProducts" />
        </div>
        <div class="overflow-x-auto">
          <table class="table">
            <!-- head -->
            <thead class="text-sm">
              <tr>
                <th>EAN / Barcode</th>
                <th>SKU</th>
                <th>Power Code</th>
                <th>Name</th>
                <th>Current Stock</th>
                <th v-if="currentTab == 4">Opname Qty</th>
                <th>Last Updated</th>
                <th v-if="currentTab == 4">Updated By</th>
                <th v-if="currentTab == 4"></th>
                <th v-if="currentTab == 2 || currentTab == 3"></th>
              </tr>
            </thead>
            <tbody v-if="loading">
              <tr>
                <td
                  :colspan="`${currentTab == 4 ? '7' : '5'}`"
                  class="text-center"
                >
                  Loading...
                </td>
              </tr>
            </tbody>
            <tbody v-if="pendingError">
              <tr>
                <td colspan="7" class="text-center" v-if="pendingError">
                  You don't have access
                </td>
              </tr>
            </tbody>
            <tbody v-if="response?.data.length > 0">
              <tr v-for="item in response.data" :key="item.name">
                <th>
                  <router-link
                    :to="{
                      name: 'product',
                      params: { productID: item.barcode },
                    }"
                    class="text-secondary hover:text-neutral"
                  >
                    {{ item.barcode }}
                  </router-link>
                </th>
                <td>{{ item.item || "-" }}</td>
                <td>{{ item.variant || "-" }}</td>
                <td>{{ item.product_name }}</td>
                <td>{{ item.stock }}</td>
                <td v-if="currentTab == 4">
                  <span v-if="item.qty > 0"> + </span> {{ item.qty }}
                </td>
                <td>{{ formatDate(item.last_updated) }}</td>
                <td v-if="currentTab == 4">{{ item.updated_by }}</td>
                <td v-if="currentTab == 4 && role == 1">
                  <button
                    class="btn btn-xs btn-primary mr-2"
                    @click="ApprovePending(item.id)"
                  >
                    Approve
                  </button>
                  <button
                    class="btn btn-xs btn-accent"
                    @click="RejectPending(item.id)"
                  >
                    Reject
                  </button>
                </td>
                <td v-if="currentTab == 2 || currentTab == 3">
                  <a
                    class="link link-secondary mt-2"
                    :href="
                      'https://barcode.tec-it.com/en/?data=' + item.barcode
                    "
                    target="_blank"
                    >Print Barcode</a
                  >
                </td>
              </tr>
            </tbody>
            <tbody v-if="response?.data.length == 0">
              <tr>
                <td
                  :colspan="`${currentTab == 4 ? '7' : '5'}`"
                  class="text-center"
                  v-if="!pendingError"
                >
                  There is no data
                </td>
                <td colspan="5" class="text-center" v-if="pendingError">
                  You don't have access
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-center mt-4">
        <div class="join">
          <button
            class="join-item btn btn-sm"
            :class="{ 'btn-disabled': currentPage == 1 }"
            @click="paginationAction('prev')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_25105_30680)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.29363 11.2932C8.10616 11.4807 8.00085 11.735 8.00085 12.0002C8.00085 12.2653 8.10616 12.5197 8.29363 12.7072L13.9506 18.3642C14.0429 18.4597 14.1532 18.5359 14.2752 18.5883C14.3972 18.6407 14.5285 18.6683 14.6612 18.6694C14.794 18.6706 14.9257 18.6453 15.0486 18.595C15.1715 18.5447 15.2831 18.4705 15.377 18.3766C15.4709 18.2827 15.5452 18.171 15.5955 18.0481C15.6457 17.9252 15.671 17.7936 15.6699 17.6608C15.6687 17.528 15.6411 17.3968 15.5887 17.2748C15.5363 17.1528 15.4601 17.0424 15.3646 16.9502L10.4146 12.0002L15.3646 7.05018C15.5468 6.86158 15.6476 6.60898 15.6453 6.34678C15.643 6.08458 15.5379 5.83377 15.3525 5.64836C15.167 5.46295 14.9162 5.35778 14.654 5.35551C14.3918 5.35323 14.1392 5.45402 13.9506 5.63618L8.29363 11.2932Z"
                  fill="#767E8C"
                />
              </g>
              <defs>
                <clipPath id="clip0_25105_30680">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="matrix(-1 0 0 1 24 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button
            class="join-item btn btn-sm"
            :class="{ 'btn-disabled': !response?.next_page }"
            @click="paginationAction('next')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_25105_30676)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.7064 11.2932C15.8938 11.4807 15.9992 11.735 15.9992 12.0002C15.9992 12.2653 15.8938 12.5197 15.7064 12.7072L10.0494 18.3642C9.95712 18.4597 9.84678 18.5359 9.72477 18.5883C9.60277 18.6407 9.47155 18.6683 9.33877 18.6694C9.20599 18.6706 9.07431 18.6453 8.95141 18.595C8.82852 18.5447 8.71686 18.4705 8.62297 18.3766C8.52908 18.2827 8.45483 18.171 8.40454 18.0481C8.35426 17.9252 8.32896 17.7936 8.33012 17.6608C8.33127 17.528 8.35886 17.3968 8.41126 17.2748C8.46367 17.1528 8.53986 17.0424 8.63537 16.9502L13.5854 12.0002L8.63537 7.05018C8.45321 6.86158 8.35241 6.60898 8.35469 6.34678C8.35697 6.08458 8.46214 5.83377 8.64755 5.64836C8.83296 5.46295 9.08377 5.35778 9.34597 5.35551C9.60816 5.35323 9.86076 5.45402 10.0494 5.63618L15.7064 11.2932Z"
                  fill="#767E8C"
                />
              </g>
              <defs>
                <clipPath id="clip0_25105_30676">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScanModal from "@/views/ScanModal.vue";
import BundleModal from "@/components/BundleModal.vue";
import { vue3Debounce } from "vue-debounce";
import axios from "axios";
import TopNavbar from "@/components/TopNavbar.vue";
import ImportExcel from "./ImportExcel.vue";
import ExportExcel from "./ExportExcel.vue";
import SyncronizeStock from "./SyncronizeStock.vue";

export default {
  name: "ProductList",
  components: {
    ScanModal,
    BundleModal,
    TopNavbar,
    ImportExcel,
    ExportExcel,
    SyncronizeStock,
  },
  directives: {
    debounce: vue3Debounce({ lock: true }),
  },
  created() {
    this.opname.is_active = JSON.parse(localStorage.getItem("stock_opname"));
    this.$watch(
      () => this.$route.params,
      () => {
        this.getProducts();
      },
      { immediate: true }
    );
  },
  data() {
    return {
      loading: true,
      opname: { is_active: JSON.parse(localStorage.getItem("stock_opname")) },
      role: localStorage.getItem("role"),
      currentPage:
        this.$route.query.page === undefined ? 1 : this.$route.query.page,
      currentTab: 0,
      pendingError: false,
      tabs: [
        {
          name: "All",
          id: 0,
        },
        {
          name: "Products",
          id: 1,
        },
        {
          name: "Bundles",
          id: 3,
        },
        {
          name: "Defects",
          id: 2,
        },
        {
          name: "Pending",
          id: 4,
        },
      ],
      response: null,
      searchValue: "",
    };
  },
  methods: {
    importData() {},

    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("default", {
        weekday: "short",
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      }).format(date);
    },
    clickTab() {
      this.currentPage = 1;
    },
    paginationAction(action) {
      this.loading = true;
      action == "next" ? this.currentPage++ : this.currentPage--;
      this.$router.push({
        query: { tab: this.currentTab, page: this.currentPage },
      });
    },
    getProducts() {
      // this.currentPage = 1;
      this.loading = true;
      this.pendingError = false;
      this.currentTab = this.$route.query.tab ? this.$route.query.tab : 0;

      if (this.$route.query.tab != 4) {
        axios
          .get(`${process.env.VUE_APP_ROOT_API}/products`, {
            headers: {
              "auth-token": localStorage.getItem("auth-token"),
            },
            params: {
              type: this.$route.query.tab,
              page: this.$route.query.page,
              search: this.searchValue,
            },
          })
          .then(
            (resp) => ((this.response = resp.data), (this.loading = false))
          );
      } else {
        this.response = null;
        axios
          .get(`${process.env.VUE_APP_ROOT_API}/pending-products`, {
            headers: {
              "auth-token": localStorage.getItem("auth-token"),
            },
            params: {
              page: this.$route.query.page,
              search: this.searchValue,
            },
          })
          .then(
            (response) => (this.response = response.data),
            (this.loading = false)
          )
          .catch((error) => {
            (this.pendingError = error.response.status == 401),
              (this.response = null);
          });
      }

      axios
        .get(`${process.env.VUE_APP_ROOT_API}/opname`, {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
        })
        .then((response) => {
          localStorage.setItem(
            response.data.config_name,
            JSON.stringify(response.data.is_active)
          );
          this.opname.is_active = JSON.parse(
            localStorage.getItem("stock_opname")
          );
        });
    },
    searchProduct() {
      this.response = null;
      this.loading = true;
      this.$router.push({ query: { tab: this.currentTab, page: 1 } });
      this.currentPage = 1;
      if (this.$route.query.page === undefined || this.$route.query.page == 1)
        this.getProducts();
    },
    ApprovePending(itemID) {
      axios
        .post(
          `${process.env.VUE_APP_ROOT_API}/pending-product/status`,
          {
            id: itemID,
            status: 1,
          },
          {
            headers: {
              "auth-token": localStorage.getItem("auth-token"),
            },
          }
        )
        .then(() => this.getProducts());
    },
    RejectPending(itemID) {
      axios
        .post(
          `${process.env.VUE_APP_ROOT_API}/pending-product/status`,
          {
            id: itemID,
            status: 2,
          },
          {
            headers: {
              "auth-token": localStorage.getItem("auth-token"),
            },
          }
        )
        .then(() => this.getProducts());
    },
  },
};
</script>
