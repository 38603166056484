import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProductView from "../views/ProductView.vue";
import LoginView from "../views/LoginView.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    props: route => ({ query: route.query.q })
  },
  {
    path: "/product/:productID",
    name: "product",
    component: ProductView,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !localStorage.getItem("auth-token")) next({ name: 'login' })
  else next()
})

export default router;
