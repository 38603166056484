<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: 'App',
    mounted() {
        document.title = "Oculus Scan";
    }
}
</script>


<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
}
</style>
