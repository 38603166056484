<template>
  <div>
    <div
      :class="{
        'w-2/3 mx-auto': role != 1,
        'join grid grid-cols-2 mx-10': role == 1,
      }"
    >
      <div class="bg-base-200 mt-4 py-4 rounded-md px-4 mx-4">
        <!-- Open the modal using ID.showModal() method -->
        <button class="btn btn-neutral w-full" @click="openModal">
          Scan here
        </button>
      </div>
      <div class="bg-base-200 mt-4 py-4 rounded-md px-4 mx-4" v-if="role == 1">
        <!-- Open the modal using ID.showModal() method -->
        <button
          class="btn btn-secondary w-full"
          onclick="opname_modal.showModal()"
          v-if="!opname.is_active"
        >
          Adjustment
        </button>
        <button
          class="btn btn-secondary w-full"
          onclick="opname_modal.showModal()"
          v-if="opname.is_active"
        >
          Finish Adjustment
        </button>
      </div>
    </div>
    <div id="scan_modal" class="modal" :class="{ 'modal-open': isModalOpen }">
      <div class="modal-box" v-if="!defectBarcode">
        <input
          ref="barcodeInput"
          type="text"
          placeholder="Scan the Product"
          class="input input-bordered w-full"
          autofocus
          v-debounce:800ms="handleBarcodeInput"
        />
        <!-- Scan Data -->
        <div class="w-full" v-if="isDone">
          <div class="divider mb-0"></div>

          <div class="alert alert-warning" v-if="!existProduct">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span
              >Product Not Found! Please fill in the details below to add a new
              product.</span
            >
          </div>
          <label class="label">
            <span class="label-text">Product Name</span>
          </label>
          <input
            type="text"
            placeholder="Name ABC"
            class="input input-bordered w-full input-sm"
            v-model="productData.product_name"
          />
          <label class="label">
            <span class="label-text">Expiry Date - DD/MM/YYYY</span>
          </label>
          <input
            type="text"
            placeholder="Example : 01/02/2023 "
            required
            class="input input-bordered w-full input-sm"
            v-model="productData.expiry_date"
          />

          <label class="label">
            <span class="label-text">Lot No</span>
          </label>
          <input
            type="text"
            placeholder="Lot No"
            required
            class="input input-bordered w-full input-sm"
            v-model="productData.lot_no"
          />

          <label class="label">
            <span class="label-text">Stock</span>
          </label>
          <select
            class="select select-bordered w-full"
            v-model="productData.action"
            @change="ChangeAction($event)"
          >
            <option v-for="action in actions" :key="action.name">
              {{ action.name }}
            </option>
          </select>

          <label class="label">
            <span class="label-text">Status</span>
          </label>
          <select
            class="select select-bordered w-full"
            v-model="productData.status"
            v-if="productData.action == 'Incoming'"
            @change="ChangeStatus($event)"
          >
            <option v-for="status in status.Incoming" :key="status.name">
              {{ status.name }}
            </option>
          </select>

          <select
            class="select select-bordered w-full"
            v-model="productData.status"
            v-if="productData.action == 'Outgoing'"
            @change="ChangeStatus($event)"
          >
            <option v-for="status in status.Outgoing" :key="status.name">
              {{ status.name }}
            </option>
          </select>

          <input
            v-if="productData.status == 'Other'"
            type="text"
            class="input input-bordered w-full input-sm mt-2"
            v-model="otherStatus"
            placeholder="Enter other status"
          />

          <label class="label">
            <span class="label-text">Quantity</span>
          </label>
          <input
            type="text"
            placeholder="1"
            class="input input-bordered w-full input-sm"
            v-model="productData.qty"
          />

          <label class="label">
            <span class="label-text">Notes</span>
          </label>
          <input
            type="text"
            placeholder="Additional Notes"
            class="input input-bordered w-full input-sm"
            v-model="productData.notes"
          />

          <div class="flex items-center mt-4">
            <input
              type="checkbox"
              class="checkbox checkbox-sm mr-2"
              v-model="checkedDefect"
            />
            Mark as Defect
          </div>
        </div>

        <div class="alert alert-error mt-2" v-if="scanErorr != ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{{ scanErorr }}</span>
        </div>

        <div class="modal-action">
          <div class="flex">
            <!-- if there is a button in form, it will close the modal -->
            <button
              class="btn btn-secondary mr-2"
              v-if="isDone"
              @click="onSave()"
            >
              Save
            </button>
            <button class="btn" @click="initiateModal()">Close</button>
          </div>
        </div>
      </div>

      <div class="modal-box" v-if="defectBarcode">
        <div class="flex flex-col items-center">
          <div>
            New barcode for defect product: <b>{{ newBarcode }}</b>
          </div>
          <a
            class="link link-secondary mt-2"
            :href="'https://barcode.tec-it.com/en/?data=' + newBarcode"
            target="_blank"
            >Print Barcode</a
          >
        </div>
        <div class="modal-action">
          <button class="btn" @click="initiateModal()">Close</button>
        </div>
      </div>
    </div>

    <dialog id="opname_modal" class="modal">
      <div class="modal-box">
        <div v-if="!opname.is_active">
          Do you want to start stock opname? <br />
          Make sure you click <b>"Finish Opname"</b> after your opname is done.
        </div>
        <div v-if="opname.is_active">
          Do you want to stop stock opname? <br />
          Any unbalanced stock will be updated.
        </div>
        <div class="modal-action">
          <form method="dialog">
            <div class="flex">
              <!-- if there is a button in form, it will close the modal -->
              <button
                class="btn btn-secondary mr-2"
                @click="startOpname()"
                v-if="!opname.is_active"
              >
                Yes, Start
              </button>
              <button
                class="btn btn-secondary mr-2"
                @click="startOpname()"
                v-if="opname.is_active"
              >
                Yes, Finish
              </button>
              <button class="btn">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
// import ScanData from '@/components/ScanData.vue'
// import OpnameData from '@/components/OpnameData.vue'
import axios from "axios";
import { vue3Debounce } from "vue-debounce";
import { Actions, Status } from "../data/StaticData";
import { isNewQRCode, isOldQRCode } from "../helpers/checkers";
import { formatTanggal } from "@/helpers/formatter";

export default {
  name: "ScanModal",
  emits: ["fetchProduct"],
  components: {
    // ScanData,
    // OpnameData
  },
  directives: {
    debounce: vue3Debounce({ lock: true }),
  },
  data() {
    return {
      isModalOpen: false,
      isDone: false,
      existProduct: false,
      actions: Actions,
      status: Status,
      productData: {},
      opname: { is_active: JSON.parse(localStorage.getItem("stock_opname")) },
      role: localStorage.getItem("role"),
      checkedDefect: false,
      defectBarcode: false,
      newBarcode: "",
      scanErorr: false,
      otherStatus: "",
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      this.$refs.barcodeInput.focus();
    },
    ChangeAction(event) {
      event.target.value == "Incoming"
        ? (this.productData.status = "Purchase from Supplier")
        : (this.productData.status = "Sell to Customer");
    },
    ChangeStatus(event) {
      if (event.target.value != "Other") this.otherStatus = "";
    },
    onSave() {
      const productType = this.checkedDefect
        ? 2
        : this.productData.type
        ? this.productData.type
        : 1;

      const body = {
        barcode: this.checkedDefect ? "" : this.productData.ean,
        product_name: this.productData.product_name,
        qty: parseInt(this.productData.qty),
        action: this.productData.action,
        status: this.otherStatus ? this.otherStatus : this.productData.status,
        notes: this.productData.notes,
        type: productType,
        expiry_date: this.productData.expiry_date,
        lot_no: this.productData.lot_no,
      };

      //   console.log(body);
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/stock-adjustment`, body, {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
        })
        .then((response) => {
          this.defectBarcode = this.checkedDefect;
          if (!this.defectBarcode) {
            this.initiateModal();
          } else {
            this.newBarcode = response.data.barcode;
          }
        })
        .catch((error) => (this.scanErorr = error.response.data.error));
    },
    initiateModal() {
      this.isModalOpen = false;
      this.isDone = false;
      if (!this.defectBarcode) this.$refs.barcodeInput.value = "";
      this.productData = {};
      this.$emit("fetchProduct");
      this.scanErorr = false;
      this.checkedDefect = false;
    },
    handleBarcodeInput(barcode) {
      var ean, expiryDate, lotNumber;
      ean = barcode;
      if (isOldQRCode(barcode)) {
        let newQrCode = isOldQRCode(barcode);
        ean = newQrCode.ean;
        expiryDate = newQrCode.expiryDate;
        lotNumber = newQrCode.lotNumber;

        this.productData.expiry_date = formatTanggal(expiryDate);
        this.productData.lot_no = lotNumber;
        this.productData.ean = ean;
      } else if (isNewQRCode(barcode)) {
        let newQrCode = isNewQRCode(barcode);
        ean = newQrCode.ean13;
        expiryDate = newQrCode.expiryDate;
        lotNumber = newQrCode.lotNumber;

        this.productData.expiry_date = formatTanggal(expiryDate);
        this.productData.lot_no = lotNumber;
        this.productData.ean = ean;
      } else {
        this.productData.expiry_date = "";
        this.productData.lot_no = "";
        this.productData.ean = barcode;
      }

      this.isDone = true;

      axios
        .get(`${process.env.VUE_APP_ROOT_API}/product/${ean}`, {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
        })
        .then((response) => {
          this.productData = {
            ...this.productData,
            ...response.data,
            action: "Incoming",
            status: "Purchase from Supplier",
            existProduct: true,
          };
        })
        .catch((error) => {
          if (error) {
            this.productData = {
              ...this.productData,
              product_name: "",
              action: "Incoming",
              status: "Purchase from Supplier",
              qty: 1,
              note: "",
            };
          }
        });
    },
    startOpname() {
      axios
        .post(
          `${process.env.VUE_APP_ROOT_API}/opname`,
          {
            is_active: !this.opname.is_active,
          },
          {
            headers: {
              "auth-token": localStorage.getItem("auth-token"),
            },
          }
        )
        .then(() => {
          localStorage.setItem("stock_opname", !this.opname.is_active),
            (this.opname.is_active = JSON.parse(
              localStorage.getItem("stock_opname")
            )),
            this.$emit("fetchProduct");
        })
        .catch((message) => console.log(message));
    },
  },
};
</script>
