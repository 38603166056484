export function formatTanggal(tanggalInput) {
  // Ambil tahun, bulan, dan tanggal dari tanggal input
  let date = tanggalInput;
  if (date.length == 4) {
    date = date + "01";
  }

  var tanggal = parseInt(date.substr(4, 2), 10); // Tambahkan 2000 karena ini mungkin tanggal di atas tahun 2000
  var bulan = parseInt(date.substr(2, 2), 10) - 1; // Kurangi 1 karena bulan dimulai dari 0 dalam objek Date
  var tahun = parseInt(date.substr(0, 2), 10) + 2000;

  // Buat objek Date
  console.log(tanggal, bulan, tahun);
  var tanggalObjek = new Date(tahun, bulan, tanggal);

  // Format tanggal, bulan, dan tahun
  var tanggalFormatted = ("0" + tanggalObjek.getDate()).slice(-2);
  var bulanFormatted = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
  var tahunFormatted = tanggalObjek.getFullYear();

  console.log(tanggalFormatted, tahunFormatted, bulanFormatted);
  return tanggalFormatted + "/" + bulanFormatted + "/" + tahunFormatted;
}
