<template>
    <div class="relative flex flex-col items-center justify-center h-screen overflow-hidden">
        <div class="w-full p-6 bg-white border-t-4 border-gray-600 rounded-md shadow-md border-top lg:max-w-lg">
            <h1 class="text-3xl font-semibold text-center text-gray-700">Oculus Scan</h1>
            <div class="space-y-4">
                <div>
                    <label class="label">
                                    <span class="text-base label-text">Username</span>
                                </label>
                    <input type="text" placeholder="Your username" class="w-full input input-bordered" v-model="loginDetail.username" autofocus />
                </div>
                <div>
                    <label class="label">
                                    <span class="text-base label-text">Password</span>
                                </label>
                    <input type="password" placeholder="Enter Password" class="w-full input input-bordered" v-model="loginDetail.password" />
                </div>
                <div>
                    <button class="btn btn-block" @click="Login()">Login</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";


export default {
    name: "LoginPage",
    data() {
        return {
            "loginDetail": {
                "username": "",
                "password": ""
            }
        }
    },
    methods: {
        Login() {
            axios
                .post(`${process.env.VUE_APP_ROOT_API}/login`, {
                    "username": this.loginDetail.username,
                    "password": this.loginDetail.password
                })
                .then((response) => {
                    localStorage.setItem("auth-token", response.headers["auth-token"])
                    localStorage.setItem("role", response.data.role)
                    this.$router.push("/")
                })
        }
    }
}
</script>