const Actions = [
    {
        name: "Incoming"
    },
    {
        name: "Outgoing"
    }
]

const Status = {
    Incoming: [
        {
            name: "Purchase from Supplier"
        },
        {
            name: "Return from Buyer"
        },
        {
            name: "Sample Return"
        },
        {
            name: "Stock Opname"
        },
        {
            name: "Other"
        }
    ],
    Outgoing: [
        {
            name: "Sell to Customer"
        },
        {
            name: "Sample Request"
        },
        {
            name: "Stock Opname"
        },
        {
            name: "Other"
        }
    ]
}

export{Actions, Status}