<template>
  <div>
    <button
      class="btn btn-sm"
      :class="[!this.isLoading ? 'btn-neutral' : 'btn-ghost']"
      @click="handleSyncronize"
    >
      <span className="loading loading-spinner" v-if="this.isLoading"></span>
      <span> {{ this.isLoading ? "LOADING" : "Syncronize" }} </span>
    </button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  emits: ["fetchProduct"],
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      error: "",
    };
  },

  methods: {
    handleSyncronize() {
      this.isLoading = true;
      axios
        .get(`${process.env.VUE_APP_ROOT_API}/syncronize`, {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
        })
        .then((resp) => {
          console.log(resp);
          this.isLoading = false;
          if (resp.status == 200) {
            this.$toast.success("Syncronis is successed! ", {
              position: "top-right",
            });
          } else {
            this.$toast.error("Syncronis is failed! ", {
              position: "top-right",
            });
          }
          this.onFetchProduct();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
:deep(.dp__action_select) {
  background: #377cfb;
}
</style>
