<template>
    <div class="navbar bg-neutral shadow-lg text-white px-16">
        <div class="navbar-start">
            <a class="btn btn-ghost normal-case text-xl" href="/">Oculus Scan</a>
        </div>
        <div class="navbar-end">
            <a class="btn btn-sm btn-neutral" @click="Logout()">Log Out ( Role: {{ role }} )</a>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            role: localStorage.getItem("role") == 1 ? 'Super Admin' : 'Admin'
        }
    },
    methods: {
        Logout() {
            axios
            .post(`${process.env.VUE_APP_ROOT_API}/logout`)
            .then(() => localStorage.clear(), this.$router.push('/login'))
        }
    }
}

</script>