<template>
  <div>
    <!-- <VueDatePicker
      v-model="date"
      range
      :enable-time-picker="false"
      :max-date="new Date()"
      @update:model-value="handleExport"
    >
      <template #trigger> -->

    <button
      class="btn btn-sm"
      :class="[!this.isLoading ? 'btn-neutral' : 'btn-ghost']"
      @click="handleExport"
    >
      <span className="loading loading-spinner" v-if="this.isLoading"></span>
      <span> {{ this.isLoading ? "LOADING" : "Export" }} </span>
    </button>
    <!-- </template>
    </VueDatePicker> -->

    <!-- <div id="my_modal_1" class="modal" :class="{ 'modal-open': isModalOpen }">
      <div class="modal-box">
        <button
          class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          @click="showModal"
        >
          ✕
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
// import axios from "axios";
export default {
  // components: { VueDatePicker },
  emits: ["fetchProduct"],
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      error: "",
    };
  },

  methods: {
    handleExport() {
      // const formattedDay0 =
      //   date[0].getDate() < 10 ? "0" + date[0].getDate() : date[0].getDate();
      // const formattedDay1 =
      //   date[1].getDate() < 10 ? "0" + date[1].getDate() : date[1].getDate();
      // const formattedMonth0 =
      //   date[0].getMonth() + 1 < 10
      //     ? "0" + (date[0].getMonth() + 1)
      //     : date[0].getMonth() + 1;
      // const formattedMonth1 =
      //   date[1].getMonth() + 1 < 10
      //     ? "0" + (date[1].getMonth() + 1)
      //     : date[1].getMonth() + 1;
      // const formattedDate0 =
      //   formattedDay0 + "-" + formattedMonth0 + "-" + date[0].getFullYear();
      // const formattedDate1 =
      //   formattedDay1 + "-" + formattedMonth1 + "-" + date[1].getFullYear();

      this.isLoading = true;
      axios
        .post(
          `${process.env.VUE_APP_ROOT_API}/products/export`,
          {
            type: this.$route.query.tab,
          },
          {
            headers: {
              "auth-token": localStorage.getItem("auth-token"),
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.isLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = "Master_Data_Product.xlsx"; // Specify the desired file name
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error downloading Excel:", error);
        });
    },
  },
};
</script>

<style scoped>
:deep(.dp__action_select) {
  background: #377cfb;
}
</style>
