export function isOldQRCode(qrCode) {
  var regexPattern =
    /^(\d{13})(\d{2})(\d{4})([A-Z0-9]{10})(\d{2})(\d{2})(\d{3})$/;
  // ("8886483910553+00+2302+00ZC055001+72+72+202");
  console.log(qrCode.length == 36);

  // Check if the QR code matches the pattern
  var matches = qrCode.match(regexPattern);

  if (qrCode.length == 36 && matches) {
    // Extracting components
    var ean = matches[1];
    var category = matches[2];
    var expiryDate = matches[3];
    var lotNumber = matches[4];
    var baseCurva = matches[5];
    var diameter = matches[6];
    var power = matches[7];

    return {
      ean: ean,
      category: category,
      expiryDate: expiryDate,
      lotNumber: lotNumber,
      baseCurva: baseCurva,
      diameter: diameter,
      power: power,
    };
  } else {
    return false;
  }
}

export function isNewQRCode(input) {
  const regex = /^010(\d{13})11(\d{6})17(\d{6})10([A-Z0-9]+)$/;
  const match = input.match(regex);

  if (match) {
    const matchTwo = /^010(\d{13})11(\d{6})17(\d{6})10([A-Z0-9]+)21(\d+)$/;
    const matchResult = input.match(matchTwo);

    if (matchResult) {
      const [, ean13, manufacturingDate, expiryDate, lotNumber, serialNumber] =
        matchResult;
      return {
        ean13,
        manufacturingDate,
        expiryDate,
        lotNumber,
        serialNumber,
      };
    } else {
      const [, ean13, manufacturingDate, expiryDate, lotNumber, serialNumber] =
        match;
      return {
        ean13,
        manufacturingDate,
        expiryDate,
        lotNumber,
        serialNumber,
      };
    }
  } else {
    return null;
  }
}
