<template>
  <div class="product">
    <ProductDetail :productID="productID" />
  </div>
</template>

<script>
// @ is an alias to /src
import ProductDetail from '@/components/ProductDetail.vue'

export default {
  name: 'ProductView',
  components: {
    ProductDetail
  },
  computed: {
    productID() {
      return this.$route.params.productID
    }
  }
}
</script>