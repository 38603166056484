<template>
    <button class="btn btn-sm btn-secondary my-4" @click="openModal">Create Bundle</button>
    <div id="my_modal_1" class="modal" :class="{'modal-open': isModalOpen}">
        <div class="modal-box" v-if="!bundleBarcode">
            <div class="flex flex-row">
                <div class="flex flex-col w-full">
                    <label class="label">
                        <span class="label-text">Bundle Name</span>
                        </label>
                    <input ref="bundleInput" type="text" placeholder="Bundle A" class="input input-bordered mr-2" autofocus v-model="bundleName" />
                </div>
                <div>
                    <label class="label">
                        <span class="label-text">Bundle Qty</span>
                        </label>
                    <input type="text" placeholder="1" class="input input-bordered w-32" v-model="bundleQty" />
                </div>
            </div>
    
            <div class="divider"></div>
    
            <div class="flex flex-row items-end" v-for="(item, index) in countProduct" :key="item.barcode">
                <div class="flex flex-col w-full">
                    <label class="label">
                        <span class="label-text">Barcode Product {{ index + 1 }}</span>
                        </label>
                    <input type="text" placeholder="Scan the Product" class="input input-bordered mr-2" v-debounce:800ms="handleBarcodeInput" v-model.lazy="item.barcode" />
                </div>
                <div>
                    <label class="label">
                        <span class="label-text">Qty</span>
                        </label>
                    <input type="number" placeholder="1" class="input input-bordered w-20 mr-2" v-model="item.qty" max="8" />
                </div>
                <div>
                    <button class="btn btn-square btn-outline" @click="deleteCountProduct(index)" :class="{'btn-disabled': countProduct.length < 2}">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
                        </button>
                </div>
            </div>
    
            <button class="btn btn-sm btn-outline btn-secondary my-4" @click="addCountProduct()">Add Product</button>
    
            <div class="alert alert-error" v-if="bundleError != '' || notExist">
                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{{ bundleError }}</span>
            </div>
    
            <div class="modal-action">
                <button class="btn btn-secondary mr-2" @click="onSave()">Create Bundle</button>
                <button class="btn" @click="initiateModal()">Close</button>
            </div>
        </div>

        <div class="modal-box" v-if="bundleBarcode">
            <div class="flex flex-col items-center">
                <div>New barcode for bundle products: <b>{{ newBarcode }}</b></div>
                <a class="link link-secondary mt-2" :href="'https://barcode.tec-it.com/en/?data=' + newBarcode" target="_blank">Print Barcode</a>
            </div>
            <div class="modal-action">
                <button class="btn" @click="initiateModal()">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import { vue3Debounce } from 'vue-debounce'
import axios from 'axios';

export default {
    emits: ["fetchProduct"],
    mounted() {},
    data() {
        return {
            notExist: false,
            bundleName: '',
            bundleQty: null,
            bundleError: '',
            isModalOpen: false,
            countProduct: [{
                    "barcode": "",
                    "qty": null
                },
                {
                    "barcode": "",
                    "qty": null
                }
            ],
            bundleBarcode: false,
            newBarcode: ''
        }
    },
    directives: {
        debounce: vue3Debounce({ lock: true })
    },
    methods: {
        openModal() {
            this.isModalOpen = true;
            this.$refs.bundleInput.focus();
        },
        handleBarcodeInput(barcode) {

            axios
                .get(`${process.env.VUE_APP_ROOT_API}/product/${barcode}`, {
                    headers: {
                        "auth-token": localStorage.getItem("auth-token")
                    },
                })
                .catch(error => {
                    if (error.response.status !== 200) {
                        this.notExist = true;
                        this.bundleError = error.response.data.error;
                    }
                })
        },
        addCountProduct() {
            this.countProduct.push({ "product_name": "", "barcode": "", "qty": null });
        },
        deleteCountProduct(index) {
            this.countProduct.splice(index, 1);
        },
        onSave() {
            axios
                .post(`${process.env.VUE_APP_ROOT_API}/product-bundle`, {
                    "bundle_name": this.bundleName,
                    "qty": parseInt(this.bundleQty),
                    "products": this.countProduct
                }, {
                    headers: {
                        "auth-token": localStorage.getItem("auth-token")
                    },
                })
                .then((response) => {
                    this.newBarcode = response.data.barcode
                    this.bundleBarcode = true;
                })
                .catch(error => this.bundleError = error.response.data.error)
        },
        initiateModal() {
            this.isModalOpen = false;
            this.bundleError = '';
            this.bundleBarcode = false;

            this.countProduct = [{
                    "barcode": "",
                    "qty": null
                },
                {
                    "barcode": "",
                    "qty": null
                }
            ];

            this.notExist = false;
            this.$emit('fetchProduct');
        }
    }
}
</script>