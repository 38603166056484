<template>
  <div>
    <button class="btn btn-neutral btn-sm" @click="showModal">Import</button>

    <div id="my_modal_1" class="modal" :class="{ 'modal-open': isModalOpen }">
      <div class="modal-box">
        <button
          class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          @click="showModal"
        >
          ✕
        </button>

        <div class="alert alert-error aler mt-4" v-if="this.error != ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{{ this.error }}</span>
        </div>
        <div
          class="flex items-center justify-center w-full"
          :class="[this.error != '' ? 'mt-2' : 'mt-7']"
        >
          <label
            for="dropzone-file"
            class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p
                class="mb-2 text-sm text-gray-500 dark:text-gray-400"
                v-if="this.fileName == ''"
              >
                <span class="font-semibold">Click to upload</span> or drag and
                drop
              </p>
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400" v-else>
                {{ this.fileName }}
              </p>
              <!-- <p class="text-xs text-gray-500 dark:text-gray-400">
                xlsx, xls, csv
              </p> -->
            </div>
            <input
              id="dropzone-file"
              type="file"
              class="hidden"
              :disabled="this.isLoading"
              @change="handleFileChange"
            />
          </label>
        </div>

        <button
          class="btn btn-active btn-md btn-block mt-3"
          :class="[!this.isLoading ? 'btn-secondary' : '']"
          @click="submitForm"
        >
          <span
            className="loading loading-spinner"
            v-if="this.isLoading"
          ></span>
          <span> {{ this.isLoading ? "LOADING" : "IMPORT" }} </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  emits: ["fetchProduct"],
  data() {
    return {
      isModalOpen: false,
      file: null,
      fileName: "",
      isLoading: false,
      error: "",
    };
  },

  methods: {
    showModal() {
      this.isModalOpen = !this.isModalOpen;
      this.file = null;
      this.fileName = "";
      this.error = "";
    },

    handleFileChange(event) {
      this.file = event.target.files[0];
      this.fileName = event.target.files[0]["name"];
      this.error = "";
    },

    submitForm() {
      const formData = new FormData();
      formData.append("file", this.file);
      this.isLoading = true;
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/products/import`, formData, {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.isLoading = false;
          this.isModalOpen = false;
          this.file = null;
          this.fileName = "";
          this.$emit("fetchProduct");
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response) {
            this.error = err.response.data.error;
          }
        });
    },
  },
};
</script>

<style></style>
